@import 'src/utils/utils';

.tarif-card {
  @include Text-12-reg;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  border-radius: 10px;
  background-color: $Main-2;
  gap: 10px;

  @media(min-wwidth: $md) {
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 20px;
    gap: 15px;
  }
  @media(min-width: $xl) {
    align-items: center;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-grow: 1;
    gap: 10px;

    @media(min-width: $md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width: $xl) {
      display: flex;
    }
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: 12%;
    flex-grow: 1;

    @media(min-width: $md) {
      padding-left: 10px;
    }

    &:not(:first-child) {

      &:before {
        @media(min-width: $md) {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          background-color: $Main-5;
          content: '';
        }
      }
    }

    &:first-child {
      padding-left: 0;

      &:before {
        content: unset;
      }
    }

    &:nth-child(odd) {

      &:before {
        content: unset;

        @media(min-width: $md) {
          content: '';
        }
      }
    }

    &:nth-child(n + 4) {
      @media(min-width: $md) {
        padding-top: 10px;
      }
      @media(min-width: $xl) {
        padding-top: unset;
      }

      &:before {
        @media(min-width: $md) {
          top: 10px;
        }
        @media(min-width: $xl) {
          top: 0;
        }
      }

      &:after {
        @media(min-width: $md) {
          position: absolute;
          top: 0;
          right: 0;
          left: 10px;
          height: 1px;
          background-color: $Main-5;
          content: '';
        }
        @media(min-width: $xl) {
          content: unset;
        }
      }
    }

    &:nth-child(3n + 4) {
      @media(min-width: $md) {
  
        &:after {
          left: 0;
        }
      }
    }

    &:nth-child(1) {
      flex-basis: 65px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &:nth-child(2) {
      flex-basis: 110px;
      flex-shrink: 0;
      flex-grow: 0;

      @media(min-width: $xl) {
        flex-basis: 160px;
      }
    }

    &:nth-child(3) {
      flex-grow: 0;
    }

    &:nth-child(4) {
      padding-left: 0;
      flex-basis: 90px;
      flex-shrink: 0;
      flex-grow: 0;

      @media(min-width: $xl) {
        padding-left: 10px;
        flex-basis: 120px;
      }

      &:before {
        content: unset;

        @media(min-width: $xl) {
          content: '';
        }
      }
    }

    &:nth-child(5) {
      flex-basis: 70px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  &__type {
    display: flex;
    flex-direction: column;

    em {
      color: $t-60;
      font-style: normal;
    }
  }

  &__pay {
    display: flex;
  }

  &__status {
    padding-top: 5px;
    padding-right: 14px;
    padding-bottom: 5px;
    padding-left: 14px;
    border-radius: 8px;
    border: 1px solid currentColor;
    white-space: nowrap;

    &--orange {
      color: $Alternative-1;
    }

    &--green {
      color: $Alternative-4;
    }

    &--disabled {
      color: $t-40;
      border-color: $Main-4;
      background-color: $Main-4;
    }
  }

  &__action {
    position: relative;

    &--active {
  
      .tarif-card__hidden {
        visibility: visible;
        opacity: 1;
      }
  
      .tarif-card__toggle {
        @media(min-width: $lg) {
          color: $Corporate-2;
        }
  
        & span:before {
          transform: translateX(-8px) translateY(-8px);
        }
  
        & span:after {
          transform: translateX(8px) translateY(8px);
        }
      }
    }
  }

  &__toggle {
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    color: $Main-1;
    transition:
      color 0.3s,
      background-color 0.3s;
    background: 0;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    span {
      position: relative;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: currentcolor;
      pointer-events: none;

      &:before {
        position: absolute;
        right: -8px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: currentcolor;
        transition: transform 0.3s;
        content: '';
      }
  
      &:after {
        position: absolute;
        right: 8px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: currentcolor;
        transition: transform 0.3s;
        content: '';
      }
    }
  }

  &__hidden {
    @include Shadow-1;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    display: flex;
    width: 190px;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 10px;
    background-color: $Main-2;
    list-style: none;
    transition:
      visibility 0.3s,
      opacity 0.3s;
    visibility: hidden;
    opacity: 0;
    flex-shrink: 0;
  }

  &__action-button {
    @include Text-14-reg;
    margin: 0;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
    border: 0;
    border-radius: 6px;
    background: 0;
    color: $Main-1;
    text-decoration: none;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        background-color: $Main-4;
      }
    }
  }
}